.centerElement {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}


.lowerLinks a:first-child{
  padding-left: 0;
}


.lowerLinks a {
  color: #002D64;
  padding-left: 30px;
  padding-right: 30px;
  font-size: .8em;
}

.lowerLinks a:before {
  background: green;
  height:2px;
}

a:hover:before,
a:focus:before {
  visibility: visible;
  transform: scaleX(1);
}

.lowerLinksActive {
  background: #f5f5f5;
  border-radius: 10px;
  transition: 0.2s ease-in-out;
}
