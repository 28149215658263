.homeImage {
  height: 160px;
  background-image: url('../../images/woman-on-phone.jpg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.homeImageMobile {
  background: #002D64;
  border-bottom: 1px solid white;
  text-align: center;
}

.imageTitle{
  padding: 20px;
}


.imageTitle h1{
  font-size: 1.5em;
  background-color: #95077D;
  color: white;
  display: inline;
  padding: 5px;
  line-height: 1.7
}


.centerItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fluidImage {
  height: auto;
  width: 100%;
}


.noCalls {
  background: #95077D;
}

.sqt {
  margin-top: 40px;
  margin-bottom: 40px;
}

.noCalls h1, .sqt h1 {
  font-size: 2.5em;
}

.switching {
  padding: 10px;
  letter-spacing: 1px;
}

.switching p {
  margin-top: -2px;
}

.switching h1, .switching h2 {
  font-size: 1.5em;
  color: #002D64;
}

.switching a {
  color: #002D64;
  text-decoration: none;
  border-bottom: 1px solid #002D64;
}


@media only screen and (min-width: 768px) {
  .homeImage {
    height: 350px;
    background-size: cover;
  }

  .imageTitle {
    margin-top: 20px;
  }

  .imageTitle h1{
    font-size: 3em;
    line-height: 1.4
  }

  .currentButton a {
    margin-top: 25px;
  }

}
