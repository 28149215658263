.footerContainer{
  color: white;
  background: '#002D64';
  padding-top: 30px;
  padding-bottom: 100px;
}

.footerTop {
  display: flex;
  font-size: 1.2em;
  font-weight: bold;
  padding-top: 20px;

}

ul {
  list-style: none;
  margin-left: -40px;
}

ul li {
  margin-top: 20px;
}

ul li a {
  color: white;
  text-decoration: none;
  font-size: .9em;
}

hr {
  height: 3px;
  background: #009FAC;
  margin-top: 40px;
}

.ep {
  padding-top: 15px;
}

.socials {
  margin-top: 40px;
}

.socials span {
  border: 1px solid gray;
  border-radius: 50%;
  padding-top: 18px;
  padding-left: 6px;
  padding-right: 2px;
  margin: 0 3px;
}

.socials span:hover{
  background-color: white;
  color:#002D64;
  cursor: pointer;
}

.socials span:last-child{
  padding-right: 6px;
}

.socials img {
  float: right;
  clear: both;
}

.scrollUp {
  cursor: pointer;
  position: fixed;
  z-index: 1;
  color: red;
  right: 20px;
  bottom: 10px;
}

.scrollUpText {
  font-size: .8em;
}

.scrollIcon {
  font-size: 2.7em;
  position: relative;
  left: 15px;
}

@media only screen and (min-width: 768pc) {
  .footerTop {
    justify-content: center;
    align-items: center;
  }
}
