.input {
  font-size: .9em;
  font-weight: bold;
}


.loginHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.loginHeading p {
  font-size: 2em;
  color: #002D64
}

button:disabled, button[disabled] {
  background: gray;
}

.formContainer {
  border: 1px solid #002D64;
  color: white;
}

.steps{
  background: #002D64;
  padding: .1px;
  padding-left: 15px;
  font-size: 1.1em;
}

.form {
  padding: 15px;
  color: black;
  font-size: .9em;
}

.formInstruction {
  margin-left: 30px;
  margin-top: -5px;
}

.formLinks{
  color: #F50057;
  text-decoration: underline;
}

.finishUp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.expandMore {
  position: relative;
  top: 8px;
  cursor: pointer;
}

.rememberWarning {
  margin-top: -4px;
  margin-left: 30px;
}

.rememberWarning:active {
  border: 2px solid black;
  border-radius: 5px;
  padding: 2px;
}

.submitButton {
  background: #0C7F88;
  color: white;
  cursor: pointer;
  padding: 12px;
  padding-left: 35px;
  padding-right: 35px;
  border: 0;
}

.submitButton:hover:not([disabled="disabled"]) {
  background: #086978;
}

.submitButton:disabled:hover {
  background: gray;
  cursor: not-allowed;
}

.submitButton span:hover {
  text-decoration: underline;
}

.whoCanSignUp {
  margin-top: 30px;
  font-size: .95em;
}

.show {
  display: block;
}
.hide {
  display: none;
}

.error {
  margin-top: 20px;
  border: 2px solid red;
  padding: 10px;
}

.error p {
  font-size: .9em;
  font-weight: bold;
  text-align: center;
  color: red;
}
