.baList ul li {
  background: url('../../images/check.png') no-repeat left center;
  list-style: none;
  margin: 0;
  padding: 10px 10px 5px 50px;
}

.listData h2 {
  font-size: 2.3em;
}

.centerItem {
  display: flex;
  align-items: center;
}

.centerItem span {
  font-size: .9em;
}

.info {
  color: #DC6915;
}

.viewAccountBtn {
  border-radius: 45px;
  padding: 12px 60px;
  background: #1D7B8A;
  border: 0;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  color: white;
}

.viewAccountBtn:hover {
  background: #95077D;
  cursor: pointer;
}

.viewAccountBtn a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2em;
  letter-spacing: 1.5px;
  border-bottom: 0;
}

.viewAccountBtn a:hover {
  border-bottom: 0;
}
