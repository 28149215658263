.menuContainer {
  font-size: .9em;
  margin: 0 auto;
  width: 80%;
}

.menus {
  margin-top: 15px;
  min-width: 160px;
}

.mIcon {
  color: blue;
}

.mText {
  position: relative;
  top: -6px;
  left: 5px;
  font-weight: bold;
}

.navLink {
  transition: all .2s ease-in;
  padding: 5px;
  text-decoration: none;
  color: #002D64;
}
.navLink:hover, {
  box-shadow: 10px 10px 10px gray;
}

.profile p{
  font-size: .9em;
  line-height: 12px;
}

.dashActive {
  color: red;
  box-shadow: 10px 10px 10px gray;
}

.logout {
  margin-left: 5px;
  color: #002D64;
  cursor: pointer;
  margin-top: 25px;
  transition: all .2s ease-in;
  display: flex;
}

.logout:hover {
  font-size: 1.3em;
  color: red;
  transition: all .2s ease-in;
}

.popOver {
  margin-left: 10px;
  margin-top: 4px;
}

@media only screen and (min-width: 768px) {
  .menus {
    margin-top: 15px;
  }

  .profile {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .profile p{
    font-size: .9em;
    line-height: 10px;
  }


}
