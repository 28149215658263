.centralizeItems {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.iconEl {
  font-size: 2em;
  margin-top: 20px;
  cursor: pointer;
  transition: all .5s ease-in-out !important;
}

.iconEl:hover {
  font-size: 2.5em;
  transform: translate(4px);
  transform: rotate(90deg);
}
