.homeImage {
  height: 160px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.personalBgImage {
  background-image: url('../../images/woman-on-phone.jpg');
}

.premierBgImage {
  background-image: url('../../images/sofaHero.jpg');
}

.businessBgImage {
  background-image: url('../../images/shellHero.jpg');
}

.contactBgImage {
  background-image: url('../../images/contactus.jpg');
}


.supportBgImage {
  background-image: url('../../images/supportCentre.jpg');
}

.financialHealthBgImage {
  background-image: url('../../images/financialHealth.jpg');
}

.premierProductsBgImage {
  background-image: url('../../images/matureCoupleLaughing.jpg');
}

.premierSelectBgImage {
  background-image: url('../../images/diamond.png');
}

.premierSupportBgImage {
  background-image: url('../../images/grandpaPhone.jpg');
}




.homeImageMobile {
  background: #002D64;
  border-bottom: 1px solid white;
  text-align: center;
}

.imageTitle{
  padding: 20px;
}


.imageTitle h1{
  font-size: 1.5em;
  background-color: #95077D;
  color: white;
  display: inline;
  padding: 5px;
  line-height: 1.7
}


.centerItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.currentButton a:hover:before,
.currentButton a:focus:before {
  visibility:hidden;
}


.currentButton a {
  background: #1D7B8A;
  border-radius: 40px;
  transition: 0s;
  display: inline-block;
  text-align: center;
  padding: 20px 30px;
  font-size: 1em;
  font-weight: bolder;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
}

.currentButton a:hover {
  border-radius: 20px;
  color: #D1D6D9;
}


.fluidImage {
  height: auto;
  width: 100%;
}


@media only screen and (min-width: 768px) {
  .homeImage {
    height: 350px;
    background-size: cover;
  }

  .imageTitle {
    margin-top: 20px;
  }

  .imageTitle h1{
    font-size: 3em;
    line-height: 1.4
  }

  .currentButton a {
    margin-top: 25px;
  }

}
