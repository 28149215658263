.listStyles {
  font-size: 1.1em;
  color: #333333;
  letter-spacing: -.6px;
}

.listStyles h1 {
  font-size: 1.7em;
  color: #337AB7;
}

.listHr {
  height: 5px;
  color:  #1D7B8A;
}

.pHeader {
  font-size: 1.1em;
  color: #61801F;
}

.listStyles ul {
  list-style-type: square;
  position: relative;
  left: 15px;
}


.listInfo {
  font-size: .8em;
  letter-spacing: 1px;
}
