.inputP {
    padding-top: 10px;
}

.adjustMargin {
    margin-top: 25px;
}

input{
    display: none;
}