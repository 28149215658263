.btnContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
}

.completed {
    padding-left: 30px;
}


.error {
    margin-top: 20px;
    border: 2px solid red;
    padding: 10px;
  }


.finishUp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .loading{
    background: rgba(0, 0, 0, .5);
    height: 100vh;
    width: 100%;
    z-index: 123;
    position: fixed;
  }

  .loading .icon {
      position: fixed;
      left: 48%;
      top: 48%;
  }