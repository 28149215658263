.inputCont{
    margin-top: 20px;
}

.inputIcon {
    font-size: 2em;
    color:#002D64;
    margin-bottom: 25px;
}

.Button {
    float: right
}

.loading{
  position: fixed;
  top: 50%;
  left: 47%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.smallShare {
  margin-left: 25px;
}

.bigShare {
  margin-left: 35px;
}