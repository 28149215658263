
.tableCont {
  height: 400px;
  overflow-y: scroll;
}


@media only screen and (min-width: 800px){
  .tableCont {
    height: 250px;
    overflow: scroll;
  }
}
