.coronaUpdate {
  background: #002D64;
  color: white;
  font-size: 180%;
  padding: 20px;
}

.coronaUpdate h2 {
  background: #95077D;
  display: inline;
  padding: 5px;
}
