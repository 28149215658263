label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: .9em;
}

.headerInfo {
    margin-bottom: 15px;
}

.formGroup {
    margin-top: 20px;
}

.cover {
    margin-bottom: 50px;
}