.cont {
  background: #002D64;
  color: white;
  padding: 35px;
  margin-bottom: 20px;
}

.cont h1 {
  font-size: 2.5em;
}
