.loginBtn {
    background: #B12F8B;
    border-radius: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;
    border: 0;
    cursor: pointer;
    text-decoration: none;
    font-size: .8em;
    font-weight: bold;
  }

.loginBtn:hover {
    background: white;
    color: #B12F8B;
}