.iconOnly {
  background-color: #002D64;
  color: white;
  height: 150vh;
  text-align: center;
}

.menuLink {
  margin-top: 30px;
}

.iconLink {
  color: white;
}

.dashActive {
  color: #EAE88d;
  box-shadow: 5px 5px 10px gray;;
}

.logout {
  cursor: pointer;
  margin-top: 35px;
}
