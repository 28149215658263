.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 50%;
}

.cardContent h4 {
  color: gray;
}

.cardContent h1 {

  font-size: 2.5em;
}

.refer {
  text-align: center;
  padding: 10px;
  background: #F4E5B8;
  font-size: .9em;
  color: gray;
  box-shadow: 20px 20px 20px gray;
}

.table {
  height: 500px;
  overflow-y: scroll;
}

.status {
  font-weight: bold;
}

@media only screen and (min-width: 800px){
  .cardContent {
    font-size: 90%;
  }

  .refer {
    margin-left: 20px;
  }

  .table {
    height: 350px;
    overflow-y: scroll;
  }
}
