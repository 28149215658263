.finanHealth {
  letter-spacing: .8px;
}
.h1 {
  font-size: 2.5em;
  color: #002D64;
}

ol li {
  margin-top: 20px;
  margin-left: -1.5em;
}

.accordionRoot {
  width: 70%;
  color: #002D64;
}

.heading{
  color: #002D64;
  font-weight: bold;
  font-size: 1em;
}
