.fluidImage {
  height: auto;
  width: 100%;
}

.imageCards {
  letter-spacing: 1.5px;
}

.imageCards a {
  color: #1D7B8A;
  text-decoration: none;
  border-bottom: 1px solid #1D7B8A;
  transition: all .5s ease-in-out;
}

.imageCards a:hover {
  font-weight: bolder;
  border-bottom: 2px solid #1D7B8A;
}
