.fluidImage {
  height: auto;
  width: 100%;
}

.imageText {
  padding: 50px 10px;
  letter-spacing: 1.4px;
}

.imageText h2, .imageText h3 {
  color: #002D64;
  letter-spacing: 1px;
}

.imageText a {
  color: #1D7B8A;
  text-decoration: none;
  border-bottom: 1px solid #1D7B8A;
  transition: all .5s ease-in-out;
}

.imageText a:hover {
  font-weight: bolder;
  border-bottom: 2px solid #1D7B8A;
}

.signupBtn {
  border-radius: 45px;
  padding: 12px 60px;
  background: #1D7B8A;
  border: 0;
  transition: all 0.4s ease-in-out;
  color: white;
}

.signupBtn:hover {
  background: #95077D;
  cursor: pointer;
}

.signupBtn a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2em;
  letter-spacing: 1.5px;
}
