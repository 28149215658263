.personalImageServices {
  background: #F5F5F5;
  padding-top: 30px;
  padding-bottom: 30px;
}

.personalImageServices a {
  color: #1D7B8A;
  text-decoration: none;
  border-bottom: 1px solid #1D7B8A
}

.centerItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .piSpan {
    width: 60%;
    text-align: center;
  }
}
