.header {
  background-color: #002D64;
  color: white;
  font-size: .8em;
}


.loginLink {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loginLink a {
  padding: 0;
  font-size: 1.1em;
}

.centerElement {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
