.links a {
  color: #1D7B8A;
  text-decoration: none;
  border-bottom: 1px solid #002D64;
  font-weight: bold;
}

.links a:hover {
  font-weight: bolder;
  color: #337AB7;
}

.hr {
  height: 1px;
  margin-top: 30px;
  background: #D8D8D8;
}


.centerItem {
  display: flex;
  align-items: center;
}

.centerItem span {
  font-size: .9em;
}



.ba h1 {
  font-size: 2em;
  color: #002D64;
}


.viewAccountBtn {
  border-radius: 45px;
  padding: 12px 60px;
  background: #1D7B8A;
  border: 0;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  color: white;
}

.viewAccountBtn:hover {
  background: #95077D;
  cursor: pointer;
}

.viewAccountBtn a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2em;
  letter-spacing: 1.5px;
  border-bottom: 0;
}

.viewAccountBtn a:hover {
  border-bottom: 0;
  color: white
}

.listStyles h1 {
  font-size: 2em;
}
