nav{
  display: flex;
}


nav a {
  padding: 20px 20px;
  text-decoration: none;
  position: relative;
  color: white;
}

.isActive {
  background: white;
  color: #002D64;
}


nav a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background: white;
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: .25s linear;
}
nav a:hover:before,
nav a:focus:before {
  visibility: visible;
  transform: scaleX(1);
}
